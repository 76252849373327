<template>
  <v-dialog
    v-model="modalShowMap"
    persistent
    max-width="500px"
    scrollable
  >
    <v-card tile>
      <v-card-title class="pa-0 ma-0" style="z-index: 10000 !important;">
        <v-btn icon @click="cerrarMap()" style="position: absolute; right:15px; top: 5px">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-overlay 
        :value="loadingMapCliente"
        color="white" 
        absolute
        opacity=".8"
      >
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
      <v-card-text>
        <v-list>
          <v-list-item>
            <v-list-item-content class="text-center">
              <span class="text-h4 font-weight-bold primary--text">Ubicación del Cliente</span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-row  no-gutters style="height: 24rem">
          <v-col cols="12">
            <div style="position:relative;">
              <div class="container-geolocation">      
                <v-row justify="center">
                  <v-col cols="12" class="pt-4 px-6">
                    <div class="v-input v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined white">
                      <div class="v-input__control">
                        <div class="v-input__slot mb-0 white elevation-2">
                          <fieldset style="top: -2px;" aria-hidden="true"><legend style="width: 0px;"></legend></fieldset>
                          <div class="v-text-field__slot">
                            <gmap-autocomplete placeholder="Buscar dirección"  @place_changed="setPlace">
                            </gmap-autocomplete>              
                          </div>
                          <div class="v-input__append-inner">
                            <div class="v-input__icon v-input__icon--append">
                              <v-btn color="secondary" plain depressed class="px-2 mt-n1"  @click="addMarker">
                                <v-icon>mdi-map-search</v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </div>          
                      </div>        
                    </div>                
                  </v-col> 
                </v-row>
              </div>
            </div>
            <gmap-map
              :center="center"
              :zoom="15"
              :options="{
                streetViewControl: false,
                rotateControl: false,
                disableDefaultUI: false,
              }"
              style="width:100%;  height: 100%; margin-right: 12px"
              ref="mapRef"
              @click="getPosition"
            >
              <gmap-marker
                :position="markers.position"
                :clickable="true"
                :draggable="false"
                @click="center=markers.position"
                :icon="require('@/assets/Markers/marker_m1_verde.png')"
              >
              
              </gmap-marker>
              
            </gmap-map> 
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col cols="12" md="6" lg="6" class="mb-0 py-0">
            <v-text-field 
              v-model="places.lat"
              outlined
              dense   
              hide-details
              readonly
              prefix="LAT"
              class="position-map"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="mb-0 py-0">
            <v-text-field 
              v-model="places.lng"
              outlined
              dense
              hide-details
              readonly
              prefix="LNG"
              class="position-map"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end py-3">
        <v-btn
          tile
          outlined
          color="blue-grey"
          class="px-6 mx-3"
          :small="$vuetify.breakpoint.mobile"
          @click="cerrarMap()"
        >
          Cancelar
        </v-btn>
        <v-btn
          tile
          color="success"
          class="px-8"
          :small="$vuetify.breakpoint.mobile"
          @click="updateUbication"
        >
          <v-icon left>mdi-map-marker-plus-outline</v-icon>
          Actualizar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name:'ModalUbicacion',
  props:{
    value: Boolean,
    ubication:{
      type: Object,
      default: () => ({}),
    },
  },
  data(){
    return {
      modalShowMap: this.value,
      loadingMapCliente: false,
      center: { lat: 8.955582, lng: -65.3220065 },
      markers: {},
      updating: false,
      currentPlace: null,
      places: 
      {
        lat: '',
        lng: '',       
      },
    }
  },
 
  watch: {
    modalShowMap (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return
      this.modalShowMap = val     
    },
    ubication(val){
       if(val.position.lat != null && val.position.lng != null){
        this.center = {
          lat: val.position.lat,
          lng: val.position.lng,
        };
        this.markers = { position: this.center };
        this.places = this.center ;
      }
      else
        this.geolocate();
    }
  },
  methods:{
    cerrarMap(){
      this.modalShowMap = false;
      this.markers =  {},
      this.places = {lat: '', lng: '',};
      this.center = { lat: 8.955582, lng: -65.3220065 };
    },
    getPosition(e){
      this.markers = { position: {lat: e.latLng.lat(), lng: e.latLng.lng() } };
      this.places.lat = e.latLng.lat();
      this.places.lng = e.latLng.lng();
    },
    async updateUbication() {
      this.loadingMapCliente = true;
      try {
        const { data } = await this.$api.put(`clientes/${this.ubication.co_cli.trim()}`, {...this.places, _method: 'put'});
        this.$parent.$emit('change');
        this.$root.$showAlert(data.message, 'success');
      } catch (error) {
        this.$root.$showAlert(
          'Lo sentimos, hubo un error al intentar actualizar la Ubicación del Cliente.', 
          'error'
        );
      }
      this.loadingMapCliente = false;
    },
    setPlace(place) {
      this.currentPlace = place;   
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };
        this.markers = { position: marker };
        this.places = marker;
        this.center = marker;
        this.currentPlace = null;
      }
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
      // this.panToMarker();
    }
  },  
}
</script>
<style lang="sass" scoped>
.container-geolocation
  position: absolute
  top: 3.5rem
  z-index: 999
  width: 100%
</style>